import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicSettingsFor = _resolveComponent("DynamicSettingsFor")!

  return (_openBlock(), _createElementBlock("section", null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "my-5 text-center" }, [
      _createElementVNode("h1", null, "Withdrawal Settings")
    ], -1)),
    _createVNode(_component_DynamicSettingsFor, { type: "withdrawal" })
  ]))
}